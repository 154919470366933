<template>
  <div>
    <div class="message sent">
      <div style="display: inline-flex; align-items: center;">
      <div class="preserve-line-breaks" style="max-width: 90%!important;">{{messageData}}</div>
        <div class="metadata">
          <div class="tick" :class="{ 'tick-animation': isRead }">
            <svg class="tick-mark-2" xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck" x="2047" y="2061">
              <path d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#92a58c"></path>
            </svg>
            <svg class="tick-mark-2" xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck-ack" x="2063" y="2076">
              <path d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#fff"></path>
            </svg>
          </div>
        </div>
    </div>
    <!-- <span class="time">{{ currentTime }}</span> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'msg-cps',
  computed: {
    mensagen () {
      return this.$store.getters.mensagem
    }
  },
  data () {
    return {
      isRead: true,
      currentTime: '',
      messageData: localStorage.getItem('x')
    }
  },
  mounted () {
    this.$emit('scroll-to-bottom')
  },
  created () {
    this.updateTime()
    this.scheduleTickAnimation()
    setTimeout(() => {
      if (localStorage.getItem('block') !== 'y') {
        this.$emit('send-message', '')
      }
    }, 3000)
  },
  methods: {
    scheduleTickAnimation () {
      setTimeout(() => {
        this.isRead = true
      }, 2000) // 5 segundos

      // Remover a classe após a animação
      setTimeout(() => {
        this.isRead = false
      }, 2500) // 1 segundo após a animação
    },
    updateTime () {
      const now = new Date()
      // Formate o tempo como preferir
      const formattedTime = `${this.padZero(now.getHours())}:${this.padZero(now.getMinutes())}`
      // Atualize o data binding com o novo tempo
      this.currentTime = formattedTime
    },
    padZero (num) {
      return num < 10 ? `0${num}` : num
    }
  }
}
</script>
<style lang="scss" scoped>
*{
  overflow: hidden;
}
.preserve-line-breaks {
  white-space: pre-line;
}

.message {
    float: right;
    max-width: 50vw!important;
    word-break:  break-word;
    position: relative;
    display: inline-block;
    margin:20px 5px;
    color: #F9FBFF;
    @media only screen and (max-width: 600px) {
    font-size: 1.125em;
    }
    padding: 10px 10px 10px 12px;
    border-radius: 20px;
    background: #7F55C4;
}
.metadata {
  width: 20px;
  // background: red;
  margin-left: 4px;
}
.metadata .tick {display: inline-block;margin-left: 8px;position: relative;top: 0;right:5px; height: 16px;width: 16px;}
.metadata .tick svg {position: absolute;transition: .5s ease-in-out;}
.metadata .tick svg:first-child {-webkit-backface-visibility: hidden;backface-visibility: hidden;-webkit-transform: perspective(800px) rotateY(180deg);transform: perspective(800px) rotateY(180deg);}
.metadata .tick svg:last-child {-webkit-backface-visibility: hidden;backface-visibility: hidden;-webkit-transform: perspective(800px) rotateY(0deg);transform: perspective(800px) rotateY(0deg);}
.metadata .tick-animation svg:first-child {-webkit-transform: perspective(800px) rotateY(0);transform: perspective(800px) rotateY(0);}
.metadata .tick-animation svg:last-child {-webkit-transform: perspective(800px) rotateY(-179.9deg);transform: perspective(800px) rotateY(-179.9deg);}
.metadata .time {
    color: rgba(0, 0, 0, .45);
    font-size: 11px;
    display: inline-block;
}
</style>

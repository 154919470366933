<template>
  <div></div>
</template>

<script>
export default {
  name: 'ChatComponent1',
  mounted () {
    setTimeout(() => {
      this.$emit('send-message', '')
    }, 5000)
  }
}
</script>

<template>
  <div>
    <div class="message sent">
      <div>{{messageData}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'msg-cps',
  computed: {
    mensagen () {
      return this.$store.getters.mensagem
    }
  },
  created () {
    if (localStorage.getItem('block') === 'y') {
      document.getElementsByClassName('status')[0].innerHTML = 'Online'
    }
  },
  mounted () {
    this.$emit('scroll-to-bottom')
  },
  data () {
    return {
      isRead: true,
      currentTime: '',
      messageData: localStorage.getItem('y')
    }
  }
}
</script>
<style lang="scss" scoped>
*{
  overflow: hidden;
}
.message {
    max-width: 60vw!important;
    word-break:  break-word;
    position: relative;
    display: inline-block;
    margin-bottom: 5px;
    color: #F9FBFF;
    @media only screen and (max-width: 600px) {
    font-size: 1.125em;
    }
    padding: 10px 10px 10px 12px;
    border-radius: 20px;
    background: #b2b2b2
}
</style>

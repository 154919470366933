<template>
  <transition name="pop" appear>
      <div class="frm received">
      <input id="tel" maxlength="10" type="tel" v-mask="'(##) #####-####'" placeholder="(_ _) _ _ _ _-_ _ _ _"  v-model="phone" />
      <button @click="celular" class="button">Confimar</button>
      </div>
  </transition>
</template>
<script>
export default {
  name: 'celular-cps',
  data () {
    return {
      showModal: true,
      mask: '(##) ####-####',
      phone: ''
    }
  },
  methods: {
    open (x) {
      this.showModal = x
      history.go(-1)
    },
    celular () {
      this.$emit('send-message', this.phone)
    }
  },
  created () {
    document.getElementsByClassName('status')[0].innerHTML = 'Online'
  },
  mounted () {
    this.showModal = true
    document.getElementById('tel').focus()
  }
}
</script>
<style scoped>
.frm {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  max-width: 400px;
  margin: 0 auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

input[type="tel"] {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #7f55c4;
  border-radius: 20px;
  margin-bottom: 20px;
  outline: none;
}

input[type="tel"]:focus {
  border-color: #7f55c4; /* Cor personalizada quando o campo está focado */
}

.button {
  background-color: #7f55c4; /* Cor do botão */
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button:hover {
  background-color: #6e45b0; /* Cor mais escura no hover */
}

.pop-enter-active, .pop-leave-active {
  transition: opacity 0.5s ease;
}

.pop-enter, .pop-leave-to /* .pop-leave-active no final do leave */ {
  opacity: 0;
}
</style>

<template>
  <div class="frm received">
    <ul v-if="filteredMotos.length && !clearFilteredMotos">
      <li v-for="(moto, index) in filteredMotos" :key="index" @click="selectMoto(moto)">{{ moto }}</li>
    </ul>
    <input id="veiculo" v-model="searchQuery" placeholder="Pesquisar modelos..." @input="handleInput" />
  </div>
</template>

<script>
import motosData from '@/utils/motos.json'

export default {
  data () {
    return {
      searchQuery: '',
      motos: [],
      clearFilteredMotos: false
    }
  },
  computed: {
    filteredMotos () {
      if (!this.searchQuery) return []
      const query = this.searchQuery.toLowerCase()
      return this.motos
        .filter(moto => moto.toLowerCase().includes(query))
        .slice(0, 4) // Limit the results to 4 items
    }
  },
  mounted () {
    this.$emit('scroll-to-bottom')
    document.getElementById('veiculo').focus()
  },
  created () {
    document.getElementsByClassName('status')[0].innerHTML = 'Online'
    this.motos = motosData.motos.flatMap(marca => marca.modelos)
  },
  watch: {
    filteredMotos (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('scroll-to-bottom')
      }
    }
  },
  methods: {
    handleInput () {
      this.clearFilteredMotos = false
    },
    selectMoto (moto) {
      this.searchQuery = moto
      this.$emit('send-message', moto)
      this.clearFilteredMotos = true
    }
  }
}
</script>

<style scoped>
input {
  width: 97%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  outline: none;
}

input:focus {
  border-color: #7f55c4; /* Cor personalizada quando o campo está focado */
}

ul {
  min-width: 95%!important;
  max-width: 95%!important;
  list-style-type: none;
  padding: 0;
}

li {
    padding: 12px 24px;
    min-width: 80%!important;
    max-width: 80%!important;
    border-radius: 24px !important;
    display: inline-block;
    cursor: pointer;
    color: #7F55C4;
    background: transparent;
    /* background-image: linear-gradient(to right, #bc002c, #ea083d); */
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
    border:1.6px solid #7F55C4;
    margin: 3px;
    text-align: center;
    font-size: 1.125em
}
</style>

<template>
  <div class="message received">
   <button @click="Send('Carro')" class="nld-opt">Carro</button>
   <button @click="Send('Moto')" class="nld-opt">Moto</button>
   <button @click="Send('Caminhao')" class="nld-opt">Caminhao</button>
   <button @click="Send('Outros')" class="nld-opt">Outros</button>
   <br>
  </div>
</template>
<script>
export default {
  name: 'ChatComponent2',
  data () {
    return {
      currentTime: ''
    }
  },
  created () {
    document.getElementsByClassName('status')[0].innerHTML = 'Online'
  },
  methods: {
    Send (x) {
      this.$emit('send-message', x)
    }
  }
}
</script>
<style scoped>
.nld-opt {
    padding: 12px 24px;
    min-width: 95%!important;
    border-radius: 24px !important;
    display: inline-block;
    cursor: pointer;
    color: #7F55C4;
    background: transparent;
    /* background-image: linear-gradient(to right, #bc002c, #ea083d); */
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
    border:1.6px solid #7F55C4;
    margin: 3px;
    text-align: center;
    font-size: 1.125em;
}
</style>

<!-- ChatComponent.vue -->
<template>
  <form id="form" class="conversation-compose">
          <input id="name" class="input-msg" name="input" placeholder="Digite seu nome" autocomplete="off" autofocus
            v-model="newMessage">
          <span class="send" @click="Send">
            <div class="circle">
              <svg viewBox="0 0 24 24" height="24" width="24" preserveAspectRatio="xMidYMid meet" class="" version="1.1"
                x="0px" y="0px" enable-background="new 0 0 24 24">
                <title>send</title>
                <path fill="currentColor"
                  d="M1.101,21.757L23.8,12.028L1.101,2.3l0.011,7.912l13.623,1.816L1.112,13.845L1.101,21.757z"></path>
              </svg>
            </div>
          </span>
        </form>
</template>

<script>
export default {
  name: 'ChatComponent1',
  data () {
    return {
      currentTime: '',
      newMessage: null
    }
  },
  mounted () {
    document.getElementById('name').focus()
  },
  created () {
    document.getElementsByClassName('status')[0].innerHTML = 'Online'
    this.updateTime()
  },
  methods: {
    Send () {
      this.$emit('send-message', this.newMessage)
    },
    updateTime () {
      const now = new Date()
      // Formate o tempo como preferir
      const formattedTime = `${this.padZero(now.getHours())}:${this.padZero(now.getMinutes())}`
      // Atualize o data binding com o novo tempo
      this.currentTime = formattedTime
    },
    padZero (num) {
      return num < 10 ? `0${num}` : num
    }
  }
}
</script>
<style lang="scss" scoped>
.conversation-compose {
  display: flex;
  align-items: center;
  padding: 10px;
  max-width: 97%!important;
  background-color: #f7f7f7;
  border-top: 1px solid #ddd;
}

.input-msg {
  flex: 1;
  padding: 10px;
  border: 1px solid #7f55c4;
  border-radius: 20px;
  font-size: 16px;
  margin-right: 10px;
  outline: none;
}

.photo {
  margin-right: 10px;
  cursor: pointer;
}

.photo i {
  font-size: 24px;
  color: #999;
}

.send {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #7F55C4; /* Nova cor */
  border-radius: 50%;
}

.send:hover {
  background-color: #6e45b0; /* Um pouco mais escuro para hover */
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
  color: #fff;
}
}
</style>

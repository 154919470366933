<template>
  <div>
    <ul v-if="suggestions.length > 0">
      <li
        v-for="(suggestion, index) in suggestions"
        :key="index"
        @click="selectSuggestion(suggestion)"
      >
        {{ formatAddress(suggestion) }}
      </li>
    </ul>
    <input
      id="end"
      class="input-msg"
      type="text"
      v-model="query"
      @input="fetchAddressSuggestions"
      placeholder="Digite o endereço"
    />
  </div>
</template>

<script>
export default {
  data () {
    return {
      query: '',
      suggestions: []
    }
  },
  mounted () {
    document.getElementById('end').focus()
  },
  created () {
    document.getElementsByClassName('status')[0].innerHTML = 'Online'
  },
  methods: {
    async fetchAddressSuggestions () {
      if (this.query.length < 3) {
        this.suggestions = []
        return
      }
      const state = 'sp' // Estado desejado
      const city = 'campinas' // Cidade desejada
      const url = `https://viacep.com.br/ws/${state}/${city}/${encodeURIComponent(this.query)}/json/`

      try {
        const response = await fetch(url)
        const data = await response.json()
        // Filtra os resultados para evitar duplicações e cep inválido
        this.suggestions = data.filter(item => item.cep && item.logradouro)
      } catch (error) {
        console.error('Erro ao buscar endereços:', error)
      }
    },
    selectSuggestion (suggestion) {
      this.$emit('send-message', `${suggestion.logradouro}, ${suggestion.bairro}\n${suggestion.localidade}-${suggestion.uf}`)
      this.suggestions = []
    },
    formatAddress (suggestion) {
      return `${suggestion.logradouro}, ${suggestion.bairro}, ${suggestion.localidade} - ${suggestion.uf}`
    }
  }
}
</script>

<style scoped>
.input-msg {
  flex: 1;
  max-width: 90vw!important;
  min-width: 90vw!important;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  font-size: 16px;
  margin-right: 10px;
  outline: none;
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
}

li {
    padding: 4px 24px;
    min-width: 80%!important;
    max-width: 80%!important;
    border-radius: 24px !important;
    display: inline-block;
    cursor: pointer;
    color: #7F55C4;
    background: transparent;
    /* background-image: linear-gradient(to right, #bc002c, #ea083d); */
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
    border:1.6px solid #7F55C4;
    margin: 3px;
    text-align: center;
    min-height: 48px!important;
    display: flex;
    align-items: center; /* Alinha verticalmente ao centro */
    justify-content: center; /* Opcional: Alinha horizontalmente ao centro */
   height: 100%; /* Certifique-se de que o pai tenha uma altura definida */
}

li:hover {
  background-color: #f0f0f0;
}
</style>
